@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Mortal Wave";
  src: url("../fonts/mortal-wave/mortal-wave.woff2") format("woff2"),
    url("../fonts/mortal-wave/mortal-wave.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.mg-t-10 {
  margin-top: 10px;
}
.mg-b-10 {
  margin-bottom: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-b-20 {
  margin-bottom: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.mg-t-30 {
  margin-top: 30px;
}
.mg-b-30 {
  margin-bottom: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.mg-t-40 {
  margin-top: 40px;
}
.mg-b-40 {
  margin-bottom: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.mg-t-50 {
  margin-top: 50px;
}
.mg-b-50 {
  margin-bottom: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.mg-t-60 {
  margin-top: 60px;
}
.mg-b-60 {
  margin-bottom: 60px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.mg-t-70 {
  margin-top: 70px;
}
.mg-b-70 {
  margin-bottom: 70px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}
.mg-t-80 {
  margin-top: 80px;
}
.mg-b-80 {
  margin-bottom: 80px;
}
.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.mg-t-90 {
  margin-top: 90px;
}
.mg-b-90 {
  margin-bottom: 90px;
}
.pt-90 {
  padding-top: 90px;
}
.pb-90 {
  padding-bottom: 90px;
}
.mg-t-100 {
  margin-top: 100px;
}
.mg-b-100 {
  margin-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 50px;
}
.mg-t-110 {
  margin-top: 110px;
}
.mg-b-110 {
  margin-bottom: 110px;
}
.pt-110 {
  padding-top: 110px;
}
.pb-110 {
  padding-bottom: 110px;
}
.mg-t-120 {
  margin-top: 120px;
}
.mg-b-120 {
  margin-bottom: 120px;
}
.pt-120 {
  padding-top: 120px;
}
.pb-120 {
  padding-bottom: 120px;
}
.mg-t-130 {
  margin-top: 130px;
}
.mg-b-130 {
  margin-bottom: 130px;
}
.pt-130 {
  padding-top: 130px;
}
.pb-130 {
  padding-bottom: 130px;
}
.mg-t-140 {
  margin-top: 140px;
}
.mg-b-140 {
  margin-bottom: 140px;
}
.pt-140 {
  padding-top: 140px;
}
.pb-140 {
  padding-bottom: 140px;
}
.mg-t-150 {
  margin-top: 150px;
}
.mg-b-150 {
  margin-bottom: 150px;
}
.pt-150 {
  padding-top: 150px;
}
.pb-150 {
  padding-bottom: 150px;
}
a {
  text-decoration: none;
}
.title {
  text-align: center;
}
.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}
@media only screen and (max-width: 992px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}

.primary-btn,
.secondary-btn {
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  padding: 10px 0px;
  font-weight: 500;
  width: 182px;
  cursor: pointer;
  text-transform: uppercase;
}
.header,
.header ul,
.behind__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.primary-btn {
  border-radius: 5px;
  background-color: #adff08;
  color: #000;
  border: 1px solid #adff08;
}
.primary-btn img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}
.primary-btn.not-active {
  background-color: #000;
  color: #adff08;
  border: 1px solid #adff08;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.primary-btn.not-active img {
  -webkit-filter: invert(91%) sepia(100%) saturate(4693%) hue-rotate(21deg)
    brightness(101%) contrast(103%);
  filter: invert(91%) sepia(100%) saturate(4693%) hue-rotate(21deg)
    brightness(101%) contrast(103%);
}
.primary-btn.not-active:hover {
  background-color: #adff08;
  color: #000;
}
.primary-btn.not-active:hover img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}
.secondary-btn {
  border-radius: 5px;
  background-color: #000;
  color: #adff08;
  border: 1px solid #adff08;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.secondary-btn.active {
  background-color: #adff08;
  color: #000;
  border: border 1px solid #adff08;
}
.secondary-btn.active img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}
.secondary-btn:hover {
  background-color: #adff08;
  color: #000;
}
.secondary-btn:hover img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}
.header {
  padding: 20px 0px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  z-index: 2000;
}
.header .logo img {
  width: 150px;
}
.header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.header ul li {
  padding: 0px 20px;
  font-family: Mortal Wave;
  font-size: 25px;
  text-transform: uppercase;
  color: #adff08;
  cursor: pointer;
}
.header ul li button {
  border-radius: 5px;
  padding: 5px 30px;
}
.header ul li button img {
  width: 90px;
}
.header ul li:last-child {
  padding-right: 0;
}
.header .navigation__toggle {
  display: none;
  border: none;
  background: none;
  z-index: 2000;
}
.header .navigation__toggle span {
  height: 3px;
  width: 30px;
  background-color: #adff08;
  display: block;
  margin-bottom: 7px;
  border-radius: 1rem;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header .navigation__toggle span:last-child {
  margin-bottom: 0;
}
.header .navigation__toggle.rotate span {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.header .navigation__toggle.rotate span:first-child {
  -webkit-transform: rotate(45deg) translate(10px, 5px);
  transform: rotate(45deg) translate(10px, 5px);
}
.header .navigation__toggle.rotate span:last-child {
  -webkit-transform: rotate(-46deg) translate(10px, -4px);
  transform: rotate(-46deg) translate(10px, -4px);
}
.header.fixed {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
@media only screen and (max-width: 1199px) {
  .header {
    padding: 20px 0px;
  }
  .header .logo {
    width: 100%;
  }
  .header .navigation__toggle {
    display: block;
  }
  .navigation {
    height: 100%;
  }
  .navigation ul {
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    z-index: 1000;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  }
  .navigation ul li {
    list-style: none;
    padding: 15px 0px;
    font-size: 30px;
  }
  .navigation ul li .primary-btn {
    font-size: 30px;
  }
  .navigation ul li:last-child {
    padding-bottom: 0;
  }
  .desktop-1199 {
    display: none;
  }
  .mobile-1199 {
    display: block;
  }
}
@media only screen and (max-width: 992px) {
  .header .logo img {
    width: 100px;
  }
}
.hero .col-md-6 {
  padding: 0 40px;
}
.hero .col-md-6:first-child {
  padding-left: 0;
}
.hero .col-md-6:last-child {
  padding-right: 0;
}
.hero__videoplayer {
  height: 100%;
}
.hero__content {
  text-align: center;
}
.hero__content button {
  border-radius: 5px;
  padding: 5px 30px;
}
.hero__content button img {
  width: 80px;
}
.hero__content img {
  width: 85%;
}
.hero__content .hero__para {
  padding: 20px 30px;
  padding-top: 0;
}
.hero__content .hero__para p {
  font-family: Montserrat, sans-serif;
  font-size: 0.95rem;
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .hero {
    padding-top: 40px;
  }
  .hero__content {
    margin-top: 20px;
  }
  .hero__content .hero__para {
    padding: 25px 0px 50px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .hero,
  .video,
  .masterpiece,
  .roadmap {
    padding-bottom: 0;
  }
}
.video__numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.single__number {
  text-align: center;
}
.single__number h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 52px;
  font-weight: 800;
  letter-spacing: 0.1em;
  -webkit-text-stroke: 1px #adff08;
  -webkit-text-fill-color: #000;
  line-height: 127.5%;
}
.single__number p {
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  color: #fff;
  text-transform: uppercase;
}
.video__image {
  text-align: center;
}
.video__image img {
  width: 95%;
}
@media only screen and (max-width: 1199px) {
  .video .video__numbers {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 992px) {
  .video .col-md-2 {
    width: 33% !important;
  }
  .video .single__number {
    margin-bottom: 30px;
  }
  .video .video__numbers {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .video {
    padding-bottom: 0 !important;
  }
  .video .col-md-2 {
    width: 33% !important;
  }
  .video .single__number {
    margin-bottom: 30px;
  }
  .video .single__number h2 {
    font-size: 40px;
  }
  .video .single__number p {
    font-size: 12px;
  }
  .video__image {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .video__videoplayer div:first-child {
    height: 200px !important;
  }
  .video .single__number {
    margin-bottom: 30px;
  }
  .video .single__number h2 {
    font-size: 30px;
  }
}
.behind .col-md-6.desktop-only {
  padding-left: 55px;
}
.behind .col-md-6.desktop-only:first-child {
  padding-right: 40px;
  padding-left: 0;
}
.behind__videoplayer {
  height: 100%;
}
.behind .col-md-5 .hero__content {
  padding-right: 30px;
}
.behind .hero__content {
  text-align: start;
}
.behind .hero__content .hero__para {
  padding: 0;
}
.behind .hero__content .hero__para p {
  font-size: 14px;
}
.behind__logo {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-column-gap: 30px;
  column-gap: 30px;
}
.behind__logo img {
  height: auto;
  width: 100px;
}
@media only screen and (max-width: 992px) {
  .behind {
    text-align: center;
  }
  .behind .hero__content {
    text-align: start;
  }
  .behind .col-md-6 {
    padding: 0px 15px !important;
  }
  .behind .behind__videoplayer {
    margin-bottom: 20px;
  }
  .behind__logo {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .behind__logo img {
    height: auto;
    width: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .behind {
    padding-bottom: 0;
    padding-top: 10px;
  }
  .behind__videoplayer div:first-child {
    height: 634px !important;
  }
  .behind__logo img {
    height: auto;
    width: 100px;
  }
}
@media only screen and (max-width: 500px) {
  .behind__videoplayer div:first-child {
    height: 460px !important;
  }
}
.howItWorks .single__step {
  width: 100%;
}
.howItWorks .single__step__image {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 395px;
  margin-bottom: 30px;
}
.howItWorks .single__step__image.image-1 {
  background-image: url(../../assets/media/images/step-one.jpg);
}
.howItWorks .single__step__image.image-2 {
  background-image: url(../../assets/media/images/pannelli.png);
}
.howItWorks .single__step__image.image-3 {
  background-image: url(../../assets/media/images/showroom.png);
}
.howItWorks .single__step h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}
.howItWorks .single__step__mob h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.howItWorks .single__step p {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  margin-top: 20px;
}
.howItWorks .single__step__mob p {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
@media only screen and (max-width: 1400px) {
  .howItWorks .single__step__image {
    height: 350px;
  }
}
@media only screen and (max-width: 1400px) {
  .howItWorks .single__step__image {
    height: 295px;
  }
}
@media only screen and (max-width: 1199px) {
  .howItWorks {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 992px) {
  .howItWorks {
    padding-top: 40px;
  }
  .howItWorks .title img {
    height: 70px;
  }
  .howItWorks .single__step__image {
    height: 700px;
  }
}
@media only screen and (max-width: 767px) {
  .howItWorks .single__step__image {
    height: 500px;
  }
}
@media only screen and (max-width: 500px) {
  .howItWorks .single__step__image {
    height: 400px;
  }
}
@media only screen and (max-width: 400px) {
  .howItWorks {
    padding-top: 40px;
  }
}
.masterpiece {
  overflow: hidden;
}
.masterpiece img.mobile-title-img {
  width: 125px;
}
.masterpiece .title p {
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
  margin: 30px 0px 40px 0px;
}
.masterpiece .title button {
  width: 130px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.masterpiece .title button.primary-btn {
  margin-right: 20px;
}
.masterpiece .title img.title-img {
  height: 50px;
}
.masterpiece .masterpiece__image {
  overflow-x: scroll;
  position: relative;
  white-space: nowrap;
  scrollbar-color: #adff08;
  scrollbar-width: 10px;
}
.masterpiece .masterpiece__image img {
  padding-bottom: 60px;
}
.masterpiece .masterpiece__image::-webkit-scrollbar {
  height: 8px;
  width: 10px;
}
.masterpiece .masterpiece__image::-moz-scrollbar {
  height: 8px;
  width: 10px;
}
.masterpiece .masterpiece__image::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #adff08;
}
.masterpiece .masterpiece__image::-webkit-scrollbar-thumb:hover {
  background: #81be07;
}
.masterpiece .masterpiece__image::-moz-scrollbar-thumb {
  border-radius: 1rem;
  background: #adff08;
}
.masterpiece .masterpiece__image::-moz-scrollbar-thumb:hover {
  background: #81be07;
}
.masterpiece .masterpiece__image .circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #adff08;
  z-index: 100;
  cursor: pointer;
}
.masterpiece .masterpiece__image .circle__content {
  background: #adff08;
  padding: 20px 20px;
  position: absolute;
  z-index: 300;
  border-radius: 0.6rem;
  -webkit-box-shadow: 0 0 30px 1px #565454;
  box-shadow: 0 0 30px 1px #565454;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.masterpiece .masterpiece__image .circle__content h2 {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 10px !important;
  text-transform: uppercase;
  margin: 0;
}
.masterpiece .masterpiece__image .circle__content p {
  font-size: 14px;
  color: #000;
  margin: 0;
  line-break: auto;
}
.masterpiece .masterpiece__image .circle__content.show__content {
  display: block;
}
@media only screen and (max-width: 992px) {
  .masterpiece {
    padding-top: 60px;
    padding-bottom: 10px;
  }
  .masterpiece .title {
    margin-bottom: 20px;
  }
  .masterpiece .title p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .masterpiece .title img.title-img {
    height: 45px;
  }
}
.roadmap__content {
  overflow: hidden;
  position: relative;
}
.roadmap__content .load {
  padding: 0px 30px;
  text-align: center;
}
.roadmap__content .load img {
  width: 120px;
  margin-bottom: 30px;
}
.roadmap__content .load ul {
  margin: 0;
  padding: 0;
}
.roadmap__content .load li {
  text-align: start;
  list-style: none;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 20px;
  column-gap: 20px;
}
.roadmap__content .load li img {
  width: 40px;
  margin: 0px;
}
@media only screen and (max-width: 992px) {
  .roadmap {
    padding-top: 50px;
  }
  .roadmap .title {
    margin-bottom: 50px;
  }
  .roadmap .title img {
    height: 50px;
  }
  .roadmap__content {
    text-align: center;
  }
  .roadmap__content button {
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  .roadmap__content button img {
    width: 100px;
  }
  .roadmap__content button.primary-btn {
    margin-right: 20px;
  }
  .roadmap__content .load {
    margin-top: 60px;
  }
}
@media (max-width: 410px) {
 
  .roadmap__content button.primary-btn ,
  .roadmap__content button.secondary-btn {
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    padding: 5px 20px;
    font-weight: 500;
    width: 144px;
    cursor: pointer;
    text-transform: uppercase;
}
}
@media (max-width: 340px) {
 
  .roadmap__content button.primary-btn ,
  .roadmap__content button.secondary-btn  {
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    padding: 2px 14px;
    font-weight: 500;
    width: 133px;
    cursor: pointer;
    text-transform: uppercase;
}
}
@media (max-width: 340px) {
 
  .roadmap__content button.primary-btn   {
    margin-right: 10px;
}
}
@media (max-width: 300px){
  .roadmap__content button.primary-btn, .roadmap__content button.secondary-btn {
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    padding: 2px 7px;
    font-weight: 500;
    width: 117px;
    cursor: pointer;
    text-transform: uppercase;
}
}
/* @media (max-width: 387px) {
  .roadmap__content button.primary-btn {
    margin-right: 0px;
  }
  .roadmap__content button.secondary-btn {
    margin-right: 0px;
  }
} */
.single__faq__question {
  padding: 20px 40px !important;
  width: 100%;
  border: 1px solid #adff08;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin-bottom: 30px;
}
.single__faq__question.change-bg {
  background-color: #adff08;
}
.single__faq__question.change-bg button {
  color: #000;
}
.single__faq__question.change-bg button img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.single__faq__question button {
  background: none;
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-align: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: none;
  padding: 0;
}
.single__faq__question .answer {
  overflow: hidden;
  height: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform-origin: top;
  transform-origin: top;
}
.single__faq__question .answer p {
  font-family: Montserrat, sans-serif;
  color: #fff;
}
.single__faq__question .answer.show__answer {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  height: auto;
  margin-top: 20px;
}
.single__faq__question .answer.show__answer p {
  color: #000;
}
.history {
  position: relative;
}
.history .col-md-6:first-child {
  padding-right: 70px;
  padding-left: 0px;
}
.history .col-md-6:last-child {
  padding-left: 70px;
}
.history__tab img {
  margin-bottom: 50px;
}
.history__tab p {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 15px;
}
.history:before {
  content: "";
  display: block;
  width: 2px;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #adff08;
  position: absolute;
}
@media only screen and (max-width: 1199px) {
  .history img {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .history {
    margin-top: 80px;
    margin-bottom: 0;
  }
  .history:before {
    display: none;
  }
  .history__tab {
    text-align: center;
    margin-bottom: 70px;
  }
}
.join__discord {
  border-top: 1px solid #adff08;
  border-bottom: 1px solid #adff08;
}
.join__discord .single__join__step h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 1.025rem;
  margin: 20px 0px;
}
.single__join__step__mob h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 1.025rem;
  margin: 20px 0px;
  text-align: center;
}
.join__discord .single__join__step p {
  font-family: Montserrat, sans-serif;
  font-size: 0.905rem;
  color: #fff;
}
.single__join__step__mob p {
  font-family: Montserrat, sans-serif;
  font-size: 0.905rem;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .newsteller {
    padding-top: 50px;
  }
  .join__discord {
    border: none;
  }
}
.footer {
  text-align: center;
}
.footer .footer__data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
  -webkit-column-gap: 30px;
  column-gap: 30px;
}
.footer .footer__data img {
  margin: 0;
}
.footer .footer__data.display-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  row-gap: 20px;
}
.footer .footer__data.display-row p {
  font-size: 0.925rem;
}
.footer .footer__data.social__links__footer {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}
.footer p {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
}
.footer img {
  margin: 20px;
}
@media only screen and (max-width: 1199px) {
  .social__links__footer img {
    width: 49px;
    height: 49px;
  }
}
@media only screen and (max-width: 992px) {
  .footer {
    padding-bottom: 30px;
  }
  .footer .footer__data {
    display: block;
    margin-bottom: 30px;
  }
  .footer .footer__data p {
    font-size: 0.8rem;
  }
  .footer .footer__data.social__links__footer img {
    width: 35px;
    height: 35px;
    margin: 10px;
  }
  .footer .footer__data a img {
    width: 50px;
    margin: 15px;
  }
  .footer .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer .col-md-4 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.artists .vp-center {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: start !important;
}
.artists .hero__content img.artist-title-img {
  width: 203px;
  margin: 0 auto;
}
@media only screen and (max-width: 1199px) {
  .artists {
    padding-top: 20px;
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 992px) {
  .artists,
  .video {
    padding-top: 0px;
  }
  .video {
    padding-bottom: 50px;
  }
  .behind .hero__content {
    text-align: center;
    margin-bottom: 80px;
  }
  .behind .hero__content .hero__para {
    text-align: start;
  }
}
@media only screen and (max-width: 992px) {
  .artists {
    text-align: center;
  }
  .artists .hero__content img.artist-title-img {
    width: auto;
    height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .artists {
    text-align: center;
  }
  .artists .hero__content {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .artists .behind__logo {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    row-gap: 10px;
    width: 100%;
  }
  .artists .behind__logo img {
    width: 900px;
  }
}
@media only screen and (max-width: 450px) {
  .artists .behind__logo img {
    width: 70px;
  }
}
@media only screen and (max-width: 400px) {
  .artists .behind__logo img {
    width: 60px;
  }
}
@media only screen and (max-width: 350px) {
  .artists .behind__logo img {
    width: 50px;
  }
}
.monsterwall__text p {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  color: #fff;
}
.monsterwall__images__container {
  margin-top: 40px !important;
}
.monsterwall__images__container .single__monster__image__container {
  overflow: hidden;
}
.monsterwall__images__container .single__monster__image__container img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.monsterwall__images__container .single__monster__image__container img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.monsterwall__images__container .single__monster__image p {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: #adff08;
  margin-top: 20px;
  margin-bottom: 2px;
}
@media only screen and (max-width: 1199px) {
  .monsterwall__video {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 992px) {
  .monsterwall__video {
    margin-bottom: 80px;
  }
  .monsterwall__text {
    margin-top: 30px;
  }
  .monsterwall__images__container .single__monster__image {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .monsterwall__video {
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .monsterwall__video div:first-child {
    height: 300px !important;
  }
}
@media only screen and (max-width: 400px) {
  .monsterwall__video div:first-child {
    height: 200px !important;
  }
}
.countdown .col-md-6 {
  padding: 0 40px;
}
.countdown .col-md-6:first-child {
  padding-left: 0;
}
.countdown .col-md-6:last-child {
  padding-right: 0;
}
.countdown .single__countdown p {
  text-transform: lowercase;
}
.countdown .single__countdown h2 {
  -webkit-text-stroke: 2px #adff08;
}
.countdown .subscription {
  padding-left: 30px;
}
.countdown .subscription__form {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 20px;
  column-gap: 20px;
}
.countdown .subscription__form input {
  border: 1px solid #adff08;
  background-color: transparent;
  width: 350px;
  height: 3rem;
  padding: 0 10px;
  border-radius: 0.2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #adff08;
}
.countdown .subscription__form input::-webkit-input-placeholder {
  color: #adff08;
}
.countdown .subscription__form input:-ms-input-placeholder {
  color: #adff08;
}
.countdown .subscription__form input::-ms-input-placeholder {
  color: #adff08;
}
.countdown .subscription__form input::placeholder {
  color: #adff08;
}
.countdown .subscription__form button {
  height: 3rem;
  padding: 0 20px;
  border-radius: 0.2rem;
}
@media only screen and (max-width: 992px) {
  .countdown__container {
    margin-top: 0;
  }
  .countdown .subscription {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
  .countdown .subscription__form {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .countdown .subscription {
    margin-bottom: 0px;
  }
  .countdown .single__countdown h2 {
    font-size: 60px;
  }
  .countdown .col-md-3 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 400px) {
  .countdown .subscription__form input {
    width: 200px;
  }
}
@media only screen and (max-width: 350px) {
  .countdown .subscription__form input {
    width: 150px;
  }
}
* {
  margin: 0;
  padding: 0;
}
body {
  background: #000 !important;
}
@media only screen and (max-width: 1200px) {
  .col-md-6 {
    padding: 0px 15px !important;
  }
}
@media only screen and (max-width: 992px) {
  .col-md-6 {
    width: 100% !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #adff08;
}
::-webkit-scrollbar-thumb:hover {
  background: #81be07;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1250px !important;
  }
}
