#scrollToTop {
  position: fixed;
  color: #adff08;
  right: 50px;
  bottom: 50px;
  font-size: 24px;
  padding: 5px;
  border: 2px solid #adff08;
  border-radius: 999px;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 99999;
  animation: autoplay2 500ms ease-out;
  transition: all 500ms;
}

@keyframes autoplay2 {
  0% {
    bottom: -100px;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 50px;
  }
}